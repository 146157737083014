import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

export const ScrollTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, [pathname]);
  return null;
};

// export const ScrollTop = () => {
//   const { pathname } = useLocation();
//   useEffect(() => {
//     scroll.scrollToTop();
//   }, [pathname]);
//   return null;
// };
