const content = [
  {
    slug: "res-alice",
    header: {
      banner: "/assets/images/Home/header/BANNER-HOME.jpg",
      logo: "/assets/images/Home/header/resalice-150x150.png",
    },
    featuresSection: {
      title: `<p>viva a verdadeira</p>
          <p>experiência de</p>
          <p>morar bem.</p>`,
      description: `Descubra a experiência de morar a poucos passos de tudo que você
      deseja em dos pontos mais exclusivos do Bessa, no Res. Alice.`,
      images: [
        "/assets/images/Enterprise/Res.Alice/alice02.png",
        "/assets/images/Enterprise/Res.Alice/alice01.jpg",
        "/assets/images/Enterprise/Res.Alice/alice02-192x300.png",
        "/assets/images/Enterprise/Res.Alice/alice03-192x300.jpg",
      ],
      specs: [
        {
          icon: "/assets/images/Enterprise/Icons/project.png",
          name: "até 64 m²",
        },
        {
          icon: "/assets/images/Enterprise/Icons/pool.png",
          name: "piscina",
        },
        {
          icon: "/assets/images/Enterprise/Icons/bed.png",
          name: "2 quartos",
        },
        {
          icon: "/assets/images/Enterprise/Icons/jacuzzi.png",
          name: "jacuzzi",
        },
        {
          icon: "/assets/images/Enterprise/Icons/kitchen.png",
          name: "área gourmet",
        },
      ],
    },
    projectSection: {
      address: "",
      plants: [
        {
          image: "/assets/images/Enterprise/Res.Alice/plant1.png",
          name: "56, 09 m²",
        },
        {
          image: "/assets/images/Enterprise/Res.Alice/plant2.png",
          name: "60, 39 m²",
        },
        {
          image: "/assets/images/Enterprise/Res.Alice/plant3.png",
          name: "54, 62 m²",
        },
        {
          image: "/assets/images/Enterprise/Res.Alice/plant4.png",
          name: "47, 96 m²",
        },
        {
          image: "/assets/images/Enterprise/Res.Alice/plant5.png",
          name: "62, 10 m²",
        },
      ],
    },
  },
  {
    slug: "new-residence",
    header: {
      banner: "/assets/images/Enterprise/NewRes/INTERNA-FIXA-NEW.png",
      logo: "/assets/images/Enterprise/NewRes/logonew.png",
    },
    featuresSection: {
      images: [
        "/assets/images/Enterprise/NewRes/imagem01a.png",
        "/assets/images/Enterprise/NewRes/imagem02.png",
        "/assets/images/Enterprise/NewRes/imagem03.png",
        "/assets/images/Enterprise/NewRes/imagem04.png",
        "/assets/images/Enterprise/NewRes/imagem05.png",
        "/assets/images/Enterprise/NewRes/imagem06.png",
        "/assets/images/Enterprise/NewRes/imagem07.png",
        "/assets/images/Enterprise/NewRes/imagem08.png",
      ],
      title: `<p>ficar em casa</p>
          <p>nunca será</p>
          <p>tão bom.</p>`,
      description: `Desde a fachada, o New Residence encanta. Moderno, versátil e inovador, nosso empreendimento veio para proporcionar novos ares ao Intermares com uma arquitetura diferenciada. `,
      specs: [
        {
          icon: "/assets/images/Enterprise/Icons/project.png",
          name: "até 79 m²",
        },
        {
          icon: "/assets/images/Enterprise/Icons/pool.png",
          name: "piscina",
        },
        {
          icon: "/assets/images/Enterprise/Icons/bed.png",
          name: "2 quartos",
        },
        {
          icon: "/assets/images/Enterprise/Icons/jacuzzi.png",
          name: "jacuzzi",
        },
        {
          icon: "/assets/images/Enterprise/Icons/kitchen.png",
          name: "área gourmet",
        },
        {
          icon: "/assets/images/Enterprise/Icons/game.png",
          name: "Brinquedoteca",
        },
        {
          icon: "/assets/images/Enterprise/Icons/fitness.png",
          name: "Espaço Fitness",
        },
      ],
    },
    projectSection: {
      address:
        "R. Golfo da Califórnia, QD 43, LT 13, esquina com Av. Mar das Antilhas - Intermares - Cabedelo - PB",
      plants: [
        {
          image: "/assets/images/Enterprise/NewRes/59,53.png",
          name: "56, 09 m²",
        },
        {
          image: "/assets/images/Enterprise/NewRes/37,10.png",
          name: "37, 10 m²",
        },
        {
          image: "/assets/images/Enterprise/NewRes/36,96.png",
          name: "36, 96 m²",
        },
        {
          image: "/assets/images/Enterprise/NewRes/34,42.png",
          name: "34, 47 m²",
        },
      ],
    },
  },
];

export default content;
