import React from 'react';
import { SectionTemplate, Container } from './styles';
import Card from '../../../components/EnterpriseCard';
import { cards } from './info';
import { useScroll } from '../../../hooks/useScroll';
import { Stagger } from './variants';

const PageSection = () => {
  const { element, controls } = useScroll();

  return (
    <SectionTemplate
      id="empreendimentos"
      variants={Stagger}
      ref={element}
      initial="hidden"
      animate={controls}
    >
      <Container>
        {cards.map((card, index) => (
          <Card card={card} index={index} />
        ))}
      </Container>
    </SectionTemplate>
  );
};

export default PageSection;
