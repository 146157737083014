import React from "react";
import { useCycle } from "framer-motion";
import { useHistory } from "react-router-dom";
import {
  CardLaytout,
  CardInfo,
  TextArea,
  Title,
  ViewMore,
  Location,
  Button,
  Pin,
  Line,
  CoverImage,
} from "./styles";

import { Hover, LineScale, CardVisible } from "./variants";

const Component = ({ card }) => {
  const [activeHover, toggleHover] = useCycle(false, true);
  const history = useHistory();

  const handleRouter = () => {
    history.push(`/empreendimentos/${card.slug}`);
  };

  return (
    <CardLaytout
      variants={CardVisible}
      onMouseEnter={() => toggleHover()}
      onMouseLeave={() => toggleHover()}
      active={activeHover}
      imageSelected={card.selected}
      image={card.image}
    >
      {/* <CoverImage
        variants={ImageScale}
        initial={false}
        animate={activeHover ? 'hover' : 'leave'}
        src={card.image}
      /> */}
      <CardInfo>
        <TextArea>
          <Title>
            <p>{card.status}</p>
            <h2>{card.type}</h2>
            <h2>{card.name}</h2>
            <Line
              initial={false}
              animate={activeHover ? "hover" : "leave"}
              variants={LineScale}
            />
          </Title>
          <ViewMore
            initial={false}
            animate={activeHover ? "hover" : "leave"}
            variants={Hover}
          >
            <Location>
              <Pin />
              <p>{card.location}</p>
            </Location>
            <Button onClick={handleRouter}>saiba mais</Button>
          </ViewMore>
        </TextArea>
      </CardInfo>
    </CardLaytout>
  );
};

export default Component;
