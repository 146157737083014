import { MdLocationOn } from 'react-icons/md';
import styled from 'styled-components';

export const CardLaytout = styled.div`
  height: 93%;
  width: 330px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media screen and (max-width: 700px) {
    height: 340px;
  }
`;

export const InfoContainer = styled.div`
  width: 60%;
  height: 90%;
  display: flex;
  flex-direction: column;
`;

export const InfoText = styled.div`
  flex: 1;
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 2rem;
`;

export const Name = styled.div`
  text-align: center;
  font-family: 'Gotham-Thin', sans-serif;
  text-transform: uppercase;
  font-size: 1.8rem;
  color: #fff;
  letter-spacing: 1px;

  p:last-child {
    font-size: 1.4rem;
  }
`;

export const Location = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 0.7rem;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  margin-top: 2rem;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
`;

export const InfoStatus = styled.div`
  height: 28%;
  border-bottom: solid var(--secondary);
  background-color: var(--primary);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p:first-child {
    font-family: 'Gotham-Ultra', sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
  }

  p:last-child {
    font-family: 'Gotham-Light', sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-top: 0.2rem;
    letter-spacing: 2px;
  }
`;

export const Pin = styled(MdLocationOn)`
  color: #fff;
  font-size: 1rem;
  margin-right: 0.3rem;
`;
