import './globals.css';
import { BrowserRouter } from 'react-router-dom';
import { Template } from './components/MainStyles';
import { MobileStateProvider } from './lib/MobileState';
import Routes from './routes';
import Footer from './components/partials/Footer';
import { ScrollTop } from './lib/scrollTop';

function App() {
  return (
    <BrowserRouter>
      <MobileStateProvider>
        <Template>
          <ScrollTop />
          <Routes />

          <Footer />
        </Template>
      </MobileStateProvider>
    </BrowserRouter>
  );
}

export default App;
