import React, { Fragment } from 'react';
import { useMobile } from '../../../lib/MobileState';
import DesktopFooter from './desktop';
import MobileFooter from './mobile';

const Footer = () => {
  const isMobile = useMobile();
  return <DesktopFooter />;
};

export default Footer;
