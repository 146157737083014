import styled from 'styled-components';
import { motion } from 'framer-motion';
import { MdLocationOn } from 'react-icons/md';

export const CardLaytout = styled(motion.div)`
  min-width: 330px;
  min-height: 600px;
  background-image: url(${({ image }) => image});
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 700px) {
    height: 650px;
  }
`;

export const CoverImage = styled(motion.img)`
  position: absolute;
  height: 100%;
  width: 100%;
  object-position: bottom;
  object-fit: cover;
`;

export const CardInfo = styled(motion.div)`
  height: 300px;
  min-height: 270px;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 11;
`;

export const TextArea = styled(motion.div)`
  height: 90%;
  width: 85%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Title = styled(motion.div)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3rem;
  padding-bottom: 0.5rem;
  position: relative;
  text-transform: uppercase;

  p {
    font-family: 'Montsserat', sans-serif;
    font-weight: 400;
    font-size: 0.6rem;
    color: #fff;
    text-transform: uppercase;
  }

  h2 {
    font-family: 'Gotham-Light', sans-serif;
    font-size: 2.6rem;
    color: #fff;
    font-weight: 200;
    letter-spacing: 1px;

    &:last-child {
      margin-top: -0.5rem;
    }
  }
`;

export const ViewMore = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0;
`;

export const Line = styled(motion.div)`
  height: 1px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  right: 30px;
  left: 0;
  transform-origin: left;
`;

export const Location = styled(motion.div)`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  p {
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 0.6rem;
    color: #fff;
    font-weight: 500;
    letter-spacing: 5px;
  }
`;

export const Button = styled(motion.button)`
  position: relative;
  align-self: center;
  padding: 15px 22px;
  border-radius: 1px;
  color: var(--secondary);
  text-transform: uppercase;
  font-size: 0.6rem;
  letter-spacing: 1px;
  font-family: 'Montserrat', sans-serif;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #e4e4e4;
  }
`;

export const Pin = styled(MdLocationOn)`
  color: #fff;
  font-size: 1rem;
`;
