import styled from "styled-components";
import { motion } from "framer-motion";

export const HeaderTemplate = styled(motion.section)`
  height: 100vh;
  background-image: url(${(props) => props.banner});
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 1000px;
  height: 90%;
  display: flex;
`;

export const LeftSide = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 6rem;
`;

export const RightSide = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  height: auto;
  width: 230px;
`;

export const Form = styled.form``;
