export const sideBarVariant = {
  closed: {
    // clipPath: 'circle(100px at 100% -100px)',
    x: 900,
    transition: {
      duration: 0.65,
      ease: 'backInOut',
    },
  },
  open: {
    // clipPath: 'circle(2500px at 100% -100px)',
    x: 0,
    transition: {
      duration: 0.25,
      ease: 'easeInOut',
      staggerChildren: 0.2,
    },
  },
};


