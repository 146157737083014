import React from 'react';
import {
  SectionTemplate,
  Title,
  MapArea,
  Container,
  ProjectContainer,
  ProjectBox,
  Area,
  ProjectTitle,
} from './styles';
import Map from '../../../components/Map';
const PageSection = ({ plants, address }) => {
  return (
    <SectionTemplate>
      <Title>
        como <span>chegar</span>
      </Title>
      <MapArea>
        {/* <img src="/assets/images/Enterprise/bmap.png" alt="" /> */}
        <Map address={address} />
      </MapArea>
      <Container>
        <ProjectTitle>plantas</ProjectTitle>
        <ProjectContainer>
          {plants?.map((item, index) => (
            <ProjectBox key={index}>
              <img src={item.image} alt="" />
              <Area>{item.name}</Area>
            </ProjectBox>
          ))}
        </ProjectContainer>
      </Container>
    </SectionTemplate>
  );
};

export default PageSection;
