import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const Slideshow = ({ children, images, styles, ...rest }) => {
  return (
    <Slide arrows={false}>
      {images?.map((image, index) => (
        <div
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            ...styles,
          }}
        >
          <>{children?.length > 0 ? children[index] : children}</>
        </div>
      ))}
    </Slide>
  );
};

export default Slideshow;
