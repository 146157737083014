import React from "react";
import {
  SectionTemplate,
  Container,
  SliderContainer,
  FeaturesContainer,
  SliderImage,
  ViewMore,
  Text,
  Title,
  SubTitle,
  GridArea,
  Grid,
  GridCell,
  InfoContainer,
  CellDot,
  DotsContainer,
  TextContainer,
  Dot,
} from "./styles";

import Slider from "../../../components/Slider";

const PageSection = ({ images, title, description, specs }) => {
  const textList = [
    "Vaga de Garagem",
    "Medição individual de Água e Energia Elétrica",
    "Estrutura Pré-instalação para Ar-condicionado",
    "250 metros da praia",
    "Louças e metais DECA",
    "Revestimento em porcelanato",
  ];

  const sliderStyles = {
    height: "500px",
    width: "100%",
    backgroundPosition: "center",
  };

  return (
    <SectionTemplate>
      <Container>
        <div className="wrapper">
          <SliderContainer>
            <SliderImage>
              <Slider styles={sliderStyles} images={images} />
              <ViewMore>
                <p>saiba mais</p>
                <p>diferenciais</p>
              </ViewMore>
            </SliderImage>
          </SliderContainer>
          <FeaturesContainer>
            <Text>
              <Title dangerouslySetInnerHTML={{ __html: title }} />
              <SubTitle>{description}</SubTitle>
            </Text>
            <GridArea>
              <Grid>
                {specs.map((item, index) => (
                  <GridCell className={`grid${index}`} key={index}>
                    <img src={item.icon} alt="" />
                    <p>{item.name}</p>
                  </GridCell>
                ))}
              </Grid>
            </GridArea>
          </FeaturesContainer>
        </div>
        <InfoContainer>
          {textList.map((item, key) => (
            <CellDot key={key}>
              <DotsContainer className={`dot-container${key}`}>
                <Dot />
              </DotsContainer>
              <TextContainer>
                <p>{item}</p>
              </TextContainer>
            </CellDot>
          ))}
        </InfoContainer>
      </Container>
    </SectionTemplate>
  );
};

export default PageSection;
