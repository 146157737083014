import React from 'react';
import {
  FooterTemplate,
  FooterContainer,
  TopContainer,
  MapContainer,
  Text,
  LogoNumber,
  Logo,
  Copy,
  MapBox,
  Map,
  AddressArea,
  Address,
  Email,
  Instagram,
} from './styles';

const Footer = () => {
  return (
    <FooterTemplate
    id='contato'>
      <FooterContainer>
        <TopContainer>
          <Text>
            <p>entre</p>
            <p>em contato</p>
            <p>conosco</p>
          </Text>
          <LogoNumber>
            <Logo src="/assets/images/Home/header/logo.png" />
            <p>
              <a href='tel:83996395858'><span>83</span> 99639 5858</a>
            </p>
          </LogoNumber>
          <AddressArea>
            <Address>
              <div>
                <p>Avenida Mar de Antilhas</p>
                <p>Intermares, Cabedelo - PB, 58102-091, sala 106</p>
              </div>
              <img src="/assets/images/Footer/pin.png" alt="" />
            </Address>
            <Email>
              <a href="mailto:contato@construtoravmengenharia">contato@construtoravmengenharia</a> <span>@</span>
            </Email>
          </AddressArea>
        </TopContainer>
        <MapContainer>
          <MapBox>
            <a href='https://goo.gl/maps/xE8QtMtg313diLmp8'>
              <Map src="/assets/images/Footer/map.png" />
            </a>
            <a href='https://www.instagram.com/vmengenharia_/'>
            <Instagram>
              <img src="/assets/images/Footer/instagram.png" alt="" />
              <div>
                <p>instagram</p>
                <p>vmengenharia_</p>
              </div>
            </Instagram>
            </a>
          </MapBox>
        </MapContainer>
        <Copy>Todos os direitos reservados à VM Egenharia. &copy;2022</Copy>
      </FooterContainer>
    </FooterTemplate>
  );
};

export default Footer;
