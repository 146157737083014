import React, { Fragment, useMemo } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import Features from "./Features";
import Project from "./Project";
import content from "./content";

const Page = () => {
  const { slug } = useParams();

  const { header, featuresSection, projectSection } = useMemo(() => {
    return content.find((item) => item.slug === slug);
  }, [slug]);

  return (
    <Fragment>
      <Header header={header} />
      <Features {...featuresSection} />
      <Project {...projectSection} />
    </Fragment>
  );
};

export default Page;
