import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import Home from '../pages/Home';
import Enterprises from '../pages/Enterprise';

const Routes = () => {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname}>
        <Route exact path="/" component={Home} />
        <Route path="/empreendimentos/:slug" component={Enterprises} />
      </Switch>
    </AnimatePresence>
  );
};

export default Routes;
