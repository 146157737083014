import React from "react";
import { useCycle } from "framer-motion";
import { Link as Scroll } from 'react-scroll';

import {
  MenuContainer,
  Menu,
  MenuList,
  List,
  ListItem,
  Burger,
  Line,
  MenuName,
  BottomContainer,
  WhatsApp,
  ScrollTopButton,
  ArrowUp,
} from "./styles";

import { sideBarVariant } from "./variants";
import { animateScroll as scroll } from "react-scroll";

const Component = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const menuItems = ["empreendimentos", "entregues", "contato"];

  const onClick = () => {
    scroll.scrollToTop();
  };

  return (
    <MenuContainer>
      <Menu initial={false} animate={isOpen ? "open" : "closed"}>
        <MenuList variants={sideBarVariant}>
          <List>
            {menuItems.map((item, key) => (
              <Scroll to={item} smooth={true} duration={900}>
                <ListItem>{item}</ListItem>
              </Scroll>
            ))}
          </List>
        </MenuList>
        <Burger onClick={() => toggleOpen()}>
          <Line
            variants={{
              closed: { rotate: 0, y: 0 },
              open: {
                y: "11px",
                rotate: "45deg",
                transition: {
                  ease: "easeInOut",
                  duration: 0.2,
                },
              },
            }}
          />
          <Line
            variants={{
              closed: { opacity: 1 },
              open: {
                opacity: 0,
                transition: { ease: "easeInOut", duration: 0.1 },
              },
            }}
          />
          <Line
            variants={{
              closed: { rotate: 0, y: 0 },
              open: {
                y: "-2px",
                rotate: "-45deg",
                transition: {
                  ease: "easeInOut",
                  duration: 0.2,
                },
              },
            }}
          />
        </Burger>
        <MenuName>Menu</MenuName>
      </Menu>
      <BottomContainer>
      <a href="https://api.whatsapp.com/send?phone=5583996395858&text=Gostaria%20de%20ter%20mais%20informa%C3%A7%C3%B5es." target="_blank" >
        <WhatsApp>
          <img
            src="/assets/images/Home/header/whatsapp.png"
            alt="Logo do WhatsApp"
          />
          <p>nosso whatsapp</p>
        </WhatsApp>
        </a>
      </BottomContainer>
      <ScrollTopButton onClick={onClick}>
        <ArrowUp />
      </ScrollTopButton>
    </MenuContainer>
  );
};

export default Component;
