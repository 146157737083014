import styled from 'styled-components';

export const FooterTemplate = styled.footer`
  height: 88vh;
  min-height: 640px;
  background-color: var(--primary);
  padding-bottom: 1rem;

  @media (max-width: 700px) {
    height: auto;
    min-height: unset;
    padding: 1rem 0;
  }
`;

export const FooterContainer = styled.div`
  max-width: 1040px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 700px) {
    max-width: unset;
  }
`;

export const TopContainer = styled.div`
  height: 35%;
  /* background-color: green; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    justify-items: center;
    min-height: 380px;
  }
`;

export const Text = styled.div`
  color: #fff;
  font-size: 1.7rem;
  line-height: 30px;
  text-transform: uppercase;
  font-family: 'Gotham-Light', sans-serif;
  letter-spacing: 1px;

  p:nth-child(2) {
    font-family: 'Gotham-Bold', sans-serif;
    color: var(--secondary);
  }

  @media (max-width: 700px) {
    text-align: center;
  }
`;

export const LogoNumber = styled.div`
  justify-self: center;
  align-self: end;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  p {
    font-family: 'Gotham-Light', sans-serif;
    font-size: 2rem;
    margin-top: 2rem;
    color: #fff;

    span {
      font-size: 1.4rem;
    }
  }

  @media (max-width: 700px) {
    margin: 0;
    align-self: center;
  }
`;

export const Logo = styled.img`
  @media (max-width: 700px) {
    display: none;
  }
`;

export const AddressArea = styled.div`
  justify-self: end;

  @media (max-width: 700px) {
    justify-self: center;
  }
`;

export const Address = styled.div`
  /* background-color: rebeccapurple; */
  text-align: right;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
  font-family: 'Gotham-XLight';

  img {
    height: 20px;
    margin-left: 0.7rem;
  }

  @media (max-width: 700px) {
    flex-direction: column-reverse;

    > div {
      text-align: center;
      margin-top: 0.5rem;
    }
  }
`;

export const Email = styled.div`
  font-family: 'Gotham-XLight';
  font-size: 0.8rem;
  color: #fff;
  margin-top: 0.5rem;
  text-align: left;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    font-family: 'Gotham-Bold', sans-serif;
    color: var(--secondary);
    font-size: 1.3rem;
    margin-left: 0.5rem;
  }

  @media (max-width: 700px) {
    flex-direction: column-reverse;
    margin-top: 1rem;

    > span {
      margin-bottom: 0.5rem;
    }
  }
`;

export const MapContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.3rem;

  @media (max-width: 700px) {
    padding: 1rem;
  }
`;

export const MapBox = styled.div`
  width: 590px;
  height: 286px;
  position: relative;

  @media (max-width: 700px) {
    height: 300px;
    width: auto;
  }
`;

export const Map = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Instagram = styled.div`
  position: absolute;
  width: 290px;
  height: 80px;
  top: 0;
  transform: translateY(-50%);
  left: 25%;
  background-color: var(--secondary);
  border-radius: 1px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  text-transform: uppercase;
  cursor: pointer;

  div {
    margin-left: 0.8rem;
    color: #fff;
    font-size: 1.2rem;

    p:first-child {
      font-family: 'Gotham-Bold';
      font-size: 1.1rem;
      margin-bottom: 0.2rem;
      letter-spacing: 1px;
    }

    p:last-child {
      font-family: 'Gotham-Light';
    }
  }

  @media (max-width: 700px) {
    left: 10%;
    top: 30px;
    display: none;
  }
`;

export const Copy = styled.div`
  text-align: center;
  font-size: 0.7rem;
  font-family: 'Gotham-Light', sans-serif;
  color: #ffffff;
`;
