import React from "react";
import { Container, Title, Form, SectionTemplate } from "./styles";

const Component = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
      <Container>
      <Title>
        <h2>
          quer saber <span>mais?</span>
        </h2>
        <p>Receba todas as informações sobre este empreendimento.</p>
      </Title>
      <Form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name"></label>
          <input type="text" placeholder="seu nome" />
        </div>
        <div>
          <label htmlFor="phone"></label>
          <input type="text" placeholder="telefone" />
        </div>
        <div>
          <label htmlFor="email"></label>
          <input type="email" placeholder="email" />
        </div>
        <button type="submit">quero mais informações</button>
      </Form>
      </Container>
  );
};

export default Component;
