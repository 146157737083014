export const cards = [
  {
    status: 'lançamento',
    type: 'res',
    name: 'providence',
    location: 'intermares',
    image: '/assets/images/Home/enterprisecard/providence-res.jpg',
    selected:
      '/assets/images/Home/enterprisecard/new-residence-selecionado.jpg',
    slug: 'res-providence',
  },
  {
    status: 'lançamento',
    type: 'garden',
    name: 'residence',
    location: 'bancários',
    image: '/assets/images/Home/enterprisecard/garden-res.jpg',
    selected: '/assets/images/Home/enterprisecard/garde-selecionado.jpg',
    slug: 'garden-residence',
  },
  {
    status: 'últimas unidades',
    type: 'new',
    name: 'residence',
    location: 'intermares',
    image: '/assets/images/Home/enterprisecard/new-residence.jpg',
    selected:
      '/assets/images/Home/enterprisecard/new-residence-selecionado.jpg',
    slug: 'new-residence',
  },
];
