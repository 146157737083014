import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { HiArrowNarrowUp } from "react-icons/hi";

export const MenuContainer = styled(motion.div)`
  position: fixed;
  top: 80px;
  right: 0;
  z-index: 999;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const Menu = styled(motion.div)`
  width: var(--sideMunuWidth);
  height: 60px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  flex-direction: column;
`;

export const MenuList = styled(motion.div)`
  width: 300px;
  height: 160px;
  right: 0;
  top: 0;
  background-color: #ffffff;
  position: absolute;
  display: flex;
  z-index: -1;
  align-items: center;
  justify-content: center;
  border-radius: 1px;
`;

export const List = styled(motion.ul)`
  /* background-color: red; */
  flex: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 90px;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
`;

export const ListItem = styled(motion.li)`
  margin-top: 0.7rem;

  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;

  text-transform: uppercase;
  color: var(--secondary);

  cursor: pointer;
`;

export const Burger = styled(motion.div)`
  cursor: pointer;
`;

export const Line = styled(motion.div)`
  height: 3px;
  width: 1.3rem;
  margin-bottom: 0.2rem;
  background-color: var(--secondary);
  border-radius: 5px;
`;

export const MenuName = styled.div`
  font-size: 0.6rem;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  color: var(--secondary);
`;

export const BottomContainer = styled.div``;

export const WhatsApp = styled.div`
  width: var(--sideMunuWidth);
  height: 100px;
  background-color: var(--secondary);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  > p {
    text-transform: uppercase;
    text-align: center;

    font-size: 0.5rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #fff;

    margin-top: 10px;
  }
`;

export const ScrollTopButton = styled.div`
  width: var(--sideMunuWidth);
  height: 60px;
  background-color: var(--secondary);
  margin-top: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

const iconCSS = css`
  fill: #fff;
  font-size: 1.4rem;
`;

export const ArrowUp = styled(HiArrowNarrowUp)`
  ${iconCSS}
`;
