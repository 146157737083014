import React from 'react';
import {
  SectionTemplate,
  GridContainer,
  Title,
  LogoArea,
  TextArea,
} from './styles';
import Card from '../../../components/DeliveredCard';

const PageSection = () => {
  const cards = [
    {
      image: '/assets/images/Home/delivered/empr01.png',
      first: 'life',
      second: 'residence',
      location: 'bessa',
      sold: true,
    },
    {
      image: '/assets/images/Home/delivered/empr02.png',
      first: 'res.',
      second: 'prof. irapuan',
      location: 'bancários',
      sold: true,
    },
    {
      image: '/assets/images/Home/delivered/empr03.png',
      first: 'res.',
      second: 'hope',
      location: 'bessa',
      sold: true,
    },
    {
      image: '/assets/images/Home/delivered/empr04.png',
      first: 'res.',
      second: 'elegance park',
      location: 'bancários',
      sold: true,
    },
    {
      image: '/assets/images/Home/delivered/empr05.png',
      first: 'res.',
      second: 'legacy',
      location: 'bancários',
      sold: true,
    },
  ];
  return (
    <SectionTemplate
      id="entregues"
    >
      <GridContainer>
        <Title>
          <LogoArea>
            <img
              src="/assets/images/Home/delivered/icon-empree.png"
              alt="empreendimetos"
            />
          </LogoArea>
          <TextArea>
            <p>empreendimetos</p>
            <p>entregues</p>
          </TextArea>
        </Title>
        {cards.map((card, index) => (
          <Card card={card} key={index} />
        ))}
      </GridContainer>
    </SectionTemplate>
  );
};

export default PageSection;
