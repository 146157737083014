import React from 'react';
import {
  CardLaytout,
  InfoContainer,
  InfoText,
  InfoStatus,
  Name,
  Location,
  Pin,
} from './styles';

const Componenet = ({ card }) => {
  return (
    <CardLaytout image={card.image}>
      <InfoContainer>
        <InfoText>
          <Name>
            <p>{card.first}</p>
            <p>{card.second}</p>
          </Name>
          <Location>
            <Pin />
            <p>{card.location}</p>
          </Location>
        </InfoText>
        <InfoStatus>
          {card.sold && (
            <>
              <p>100%</p>
              <p>Vendido</p>
            </>
          )}
        </InfoStatus>
      </InfoContainer>
    </CardLaytout>
  );
};

export default Componenet;
