import styled from 'styled-components';

export const SectionTemplate = styled.section`
  height: 95vh;
  min-height: 680px;
  max-height: 900px;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 700px) {
    height: auto;
    min-height: unset;
    max-height: unset;
    padding: 1rem 0;
  }
`;

export const Container = styled.div`
  width: 1150px;
  margin: 0 auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  .wrapper {
    display: flex;
    height: 83%;
  }

  @media (max-width: 700px) {
    width: auto;
    flex-direction: column;

    .wrapper {
      flex-direction: column;
    }
  }
`;

export const InfoContainer = styled.div`
  width: 840px;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  @media (max-width: 700px) {
    display: none;
  }
`;

export const CellDot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Dot = styled.div`
  border: 2px solid #fff;
  border-radius: 50%;
  height: 10px;
  width: 10px;
`;

export const DotsContainer = styled.div`
  /* background-color: green; */
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:not(div.dot-container5) {
    &::after {
      content: '';
      position: absolute;
      height: 1px;
      left: 53%;
      right: 0;
      background-color: #b3b2b2;
    }
  }

  &:not(div.dot-container0) {
    &::before {
      content: '';
      position: absolute;
      height: 1px;
      left: 0;
      right: 53%;
      background-color: #b3b2b2;
    }
  }
`;

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 0.67rem;
  text-align: center;
  color: #ffffff;
  font-family: 'Gotham-XLight', sans-serif;
  margin-top: -0.4rem;

  > p {
    max-width: 90%;
  }
`;

// export const DotsContainer = styled.div`
//   width: 790px;
//   margin: 0 auto;
//   /* border: solid red; */
//   display: grid;
//   grid-template-columns: repeat(6, 1fr);
// `;

// export const DotsCell = styled.div`
//   /* background-color: green; */
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: relative;

//   &:not(div:first-child) {
//     &::before {
//       content: '';
//       position: absolute;
//       height: 1px;
//       background-color: #b3b2b2;
//       left: 0;
//       right: 54%;
//     }
//   }

//   &:not(div:last-child) {
//     &::after {
//       content: '';
//       position: absolute;
//       height: 1px;
//       background-color: #b3b2b2;
//       right: 0;
//       left: 54%;
//     }
//   }
// `;

// export const TextContainer = styled.div`
//   margin: 0 auto;
//   display: grid;
//   grid-template-columns: repeat(6, 1fr);
//   font-size: 0.7rem;
//   text-align: center;
//   text-transform: capitalize;
//   font-family: 'Gotham-XLight', sans-serif;
//   grid-column-gap: 0.7rem;
// `;

// export const TextCell = styled.div`
//   /* background-color: red; */
//   display: flex;
//   justify-content: center;
//   color: #ffff;
// `;

// export const Dot = styled.div`
//   border: 2px solid #b3b2b2;
//   border-radius: 50%;
//   height: 10px;
//   width: 10px;
// `;

export const SliderContainer = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    width: auto;
  }
`;

export const SliderImage = styled.div`
  height: 500px;
  width: 330px;
  position: relative;
`;

export const ViewMore = styled.div`
  position: absolute;
  bottom: 40px;
  right: -20px;
  transform: translateY(50%);
  width: 270px;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: var(--secondary);
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1px;
  font-size: 1.1rem;

  @media (max-width: 700px) {
    width: 200px;
    height: 100px;
    bottom: 50px;
    right: 6px;
  }
  p:last-child {
    font-weight: bold;
  }
`;

export const FeaturesContainer = styled.div`
  flex: 1;
  display: flex;
  height: 90%;
  margin: auto 0;
  flex-direction: column;
  padding-left: 1.5rem;
  padding-top: 0.8rem;
  /* background-color: blue; */

  @media (max-width: 700px) {
    padding: 1rem 0;
  }
`;

export const Text = styled.div`
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
  padding-left: 1rem;
  letter-spacing: 1px;
`;

export const Title = styled.div`
  font-size: 1.5rem;
  text-transform: uppercase;

  p {
    font-family: 'Gotham-Thin', sans-serif;
    color: #fff;
  }

  p:last-child {
    font-family: 'Gotham-Bold', sans-serif;
    color: var(--secondary);
  }

  @media (max-width: 700px) {
    p {
      padding-left: 1rem;
    }

    p ~ p {
      padding-left: 1rem;
    }
  }
`;

export const SubTitle = styled.div`
  font-family: 'Gotham-XLight', sans-serif;
  font-size: 0.8rem;
  color: #fff;
  padding-right: 3rem;
`;

export const GridArea = styled.div`
  flex: 1;
`;

export const Grid = styled.div`
  height: 70%;
  display: grid;
  padding-left: 0.8rem;
  margin-top: 2rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 5rem;
  grid-row-gap: 2rem;

  @media (max-width: 700px) {
    height: auto;
    grid-template-columns: 1fr;
    justify-items: center;
    padding: 0;
    gap: 3rem;
    margin-top: 4rem;
  }
`;

export const GridCell = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  &.grid1,
  &.grid3,
  &.grid5 {
    /* justify-self: center; */
    /* background-color: red; */

    @media (max-width: 700px) {
      justify-self: unset;
    }
  }

  p {
    text-transform: uppercase;
    color: #fff;
    font-size: 0.85rem;

    letter-spacing: 2px;
    font-family: 'GothamBook', sans-serif;
  }
`;
