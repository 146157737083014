import styled from 'styled-components';

export const SectionTemplate = styled.section`
  height: 90vh;
  min-height: 670px;
  max-height: 700px;
  background-color: var(--secondary);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 700px) {
    height: auto;
    min-height: unset;
    max-height: unset;
    padding-bottom: 1rem;
  }
`;

export const Title = styled.div`
  text-align: center;
  padding: 1.1rem 0;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Gotham-Light', sans-serif;
  letter-spacing: 1px;
  font-size: 1.2rem;
  span {
    font-family: 'Gotham-Bold', sans-serif;
  }
`;

export const MapArea = styled.div`
  height: 200px;
  /* border: solid red; */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Container = styled.div`
  width: 1080px;
  flex: 1;
  /* background-color: blue; */
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 700px) {
    width: auto;
  }
`;

export const ProjectTitle = styled.div`
  align-self: flex-start;
  margin-bottom: 1rem;
  padding-left: 4.5rem;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.5rem;
  font-family: 'Gotham-Bold', sans-serif;
  letter-spacing: 1px;

  @media (max-width: 700px) {
    padding: 2rem 0;
    align-self: center;
    font-size: 1.7rem;
  }
`;

export const ProjectContainer = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 700px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const ProjectBox = styled.div`
  /* border: solid red; */
  display: flex;
  flex-direction: column;

  img {
    height: 210px;
    width: auto;
    object-fit: cover;
  }
`;

export const Area = styled.p`
  align-self: center;
  border: 2px solid #fff;
  margin-top: 1rem;
  color: #fff;
  font-size: 1rem;
  font-family: 'Gotham-Ultra';
  padding: 5px 10px;
  text-transform: uppercase;
`;
