import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { HeaderTemplate, Container, LeftSide, RightSide, Logo } from "./styles";
import Slider from "../../../components/Slider";
import { PageFade } from "../../../components/GlobalVariants";
import Form from "../../../components/Form";

const PageHeader = ({ header }) => {
  return (
    <HeaderTemplate
      variants={PageFade}
      initial="hidden"
      animate="visible"
      exit="exit"
      banner={header?.banner}
    >
      <Container>
        <LeftSide>
          <Logo src={header?.logo} />
        </LeftSide>
        <RightSide>
          <Form />
        </RightSide>
      </Container>
    </HeaderTemplate>
  );
};

export default PageHeader;
