import React from 'react';
import { SectionTemplate, VideoContainer } from './styles';
import ReactPlayer from 'react-player/youtube';

const PageSection = () => {
  return (
    <SectionTemplate>
      <VideoContainer>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=eK7ezG5XKZo"
          width="100%"
          height="100%"
        />
      </VideoContainer>
    </SectionTemplate>
  );
};

export default PageSection;
