import React from 'react';
import { Link as Scroll } from 'react-scroll';
import axios from 'axios';
import {
  SectionTemplate,
  Container,
  Circle,
  InnerCircle,
  Button,
  LogoBox,
  Logo,
  Arrow,
  BannerContainer,
  BannerLogoArea,
  BannerLogo,
  BannerTitle,
} from './styles';
import SideMenu from '../../../components/SideMenu';
import Slider from '../../../components/Slider';
import { PageFade } from '../../../components/GlobalVariants';

const PageHeader = () => {
  const sliderStyles = {
    height: '100vh',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const banners = [
    '/assets/images/Home/header/banner-Joy-new.png',
    '/assets/images/Home/header/banner-Providence-new.png',
    '/assets/images/Home/header/banner-2.png',
    '/assets/images/Home/header/BANNER-HOME.jpg',
    '/assets/images/Home/header/banner-new.png',
  ];

  return (
    <SectionTemplate
      variants={PageFade}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Slider styles={sliderStyles} images={banners}>
      <BannerContainer>
          <BannerLogoArea>
            
          </BannerLogoArea>
          <BannerTitle>
            <div>
            <BannerLogo src="/assets/images/Home/header/joy-logo-new.png" />
              <p>Ser Joy é viver</p>
              <p>o conforto em</p>
              <p>sua plenitude</p>
            </div>
          </BannerTitle>
        </BannerContainer>
        <BannerContainer>
          <BannerLogoArea>
            
          </BannerLogoArea>
          <BannerTitle>
            
            <div>
            <BannerLogo src="/assets/images/Home/header/logo-providence.png" />
              <p>Alcance o</p>
              <p>Incomparável</p>
            </div>
          </BannerTitle>
        </BannerContainer>
        <BannerContainer>
          <BannerLogoArea>
            <BannerLogo src={null} />
          </BannerLogoArea>
          <BannerTitle>
            <div>
              <p>Um Lugar para</p>
              <p>Inspirar e expirar</p>
              <p>Saúde.</p>
            </div>
          </BannerTitle>
        </BannerContainer>
        <BannerContainer>
          <BannerLogoArea>
            
          </BannerLogoArea>
          <BannerTitle>
            <div>
            <BannerLogo src="/assets/images/Home/header/res-alice.png" />
              <p>viva a verdadeira</p>
              <p>experiência de</p>
              <p>morar bem.</p>
            </div>
          </BannerTitle>
        </BannerContainer>
        <BannerContainer>
          <BannerLogoArea>
            
          </BannerLogoArea>
          <BannerTitle>
            <div>
            <BannerLogo src="/assets/images/Home/header/logonew2.png" />
              <p>ficar em casa</p>
              <p>nunca será</p>
              <p>tão bom.</p>
            </div>
          </BannerTitle>
        </BannerContainer>
      </Slider>
      <SideMenu />
      <LogoBox>
        <Logo src="/assets/images/Home/header/logo.png" />
      </LogoBox>
      <Container>
        <Button>conheça o projeto</Button>
        <Circle>
          <Scroll to="empreendimentos" smooth={true} duration={900}>
            <InnerCircle>
              <Arrow src="/assets/images/Home/header/arrow.png" />
            </InnerCircle>
          </Scroll>
        </Circle>
      </Container>
    </SectionTemplate>
  );
};

export default PageHeader;
