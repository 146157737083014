import React, { Fragment } from "react";
import Header from "./Header";
import Enterprises from "./Enterprises";
import Delivered from "./Delivered";
import Video from "./DisplayVideo";

const Page = () => {
  return (
    <Fragment>
      <Header />
      <Enterprises />
      <Delivered />
      <Video />
    </Fragment>
  );
};

export default Page;
