import React, { useState, useEffect, Fragment } from 'react';
import ReactMapGl, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Geocode from 'react-geocode';
import { Location } from './styles';
import { IoLocationSharp } from 'react-icons/io';

const Component = ({ address }) => {
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [loading, setLoading] = useState(true);
  const [viewport, setViewport] = useState({
    latitude: null,
    longitude: null,
    zoom: 14,
    width: '100%',
    height: '100%',
  });

  useEffect(() => {
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        console.log(lat, lng);
        setLat(lat);
        setLng(lng);
        setViewport({ ...viewport, latitude: lat, longitude: lng });
        setLoading(false);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [address]);

  Geocode.setLanguage('pt-BR');
  Geocode.setRegion('br');
  Geocode.setApiKey('AIzaSyDYBwK3nM3fQPZbOExyVDGExPLTAY2sHLQ');

  if (loading) {
    return null;
  }

  return (
    <ReactMapGl
      {...viewport}
      onViewportChange={(vp) => setViewport(vp)}
      mapboxApiAccessToken="pk.eyJ1IjoiZmFiaXVzbWF4aW11cyIsImEiOiJja283bXFqcmoyMjFzMm9xd3ZidzFubnNzIn0.BOr7FbGDd7iwAsUi43SW6w"
    >
      <Marker key={address} latitude={lat} longitude={lng}>
        <Location />
      </Marker>
    </ReactMapGl>
  );
};

export default Component;
