import styled from 'styled-components';

export const SectionTemplate = styled.section`
  height: 90vh;
  min-height: 650px;
  background-color: var(--secondary);
  display: flex;

  @media screen and (max-width: 700px) {
    height: 60vh;
    min-height: unset;
  }
`;

export const VideoContainer = styled.div`
  width: 850px;
  height: 85%;
  margin: auto;

  @media screen and (max-width: 700px) {
    height: 340px;
    width: 330px;
  }
`;
