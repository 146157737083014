import React, { Fragment } from 'react';
import PageFooter from './PageFooter';

const Footer = () => {
  return (
    <Fragment>
      <PageFooter />
    </Fragment>
  );
};

export default Footer;
