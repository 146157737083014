import React, { createContext, useContext, useState, useEffect } from 'react';

const LocalStateContext = createContext();
const LocalStateProvider = LocalStateContext.Provider;

const MobileStateProvider = ({ children }) => {
  const mobileThreshold = 700;
  const [mobileActive, setMobileActive] = useState(
    window.innerWidth < mobileThreshold
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      setMobileActive(window.innerWidth < mobileThreshold);
    });
  }, []);

  return (
     <LocalStateProvider value={mobileActive}>{children}</LocalStateProvider>
  );
};

const useMobile = () => {
  const all = useContext(LocalStateContext);
  return all;
};

export { MobileStateProvider, useMobile };
