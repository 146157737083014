import styled from "styled-components";

export const Container = styled.div`
  height: 350px;
  width: 300px;
  background-color: #2b292a;

  padding: 0.9rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const SectionTemplate = styled.div`
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h2 {
    text-transform: uppercase;
    color: #fff;
    font-family: "Gotham-Thin", sans-serif;
    font-size: 1.1rem;

    span {
      color: var(--secondary);
      font-family: "Gotham-Bold";
    }
  }

  > p {
    margin-top: 15px;

    text-align: center;
    font-family: "Gotham-XLight";
    font-size: 0.95rem;
    color: #fff;

    width: 90%;
  }
`;

export const Form = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
    margin-top: 0.8rem;
    input {
      width: 100%;
      background-color: transparent;
      border-bottom: 1px solid #fff;
      padding: 0.4rem;
      color: #fff;
      font-family: inherit;

      &::placeholder {
        font-size: 0.7rem;
        text-transform: uppercase;
      }
    }
  }

  > button {
    margin-top: 3rem;
    position: relative;
    overflow: -moz-hidden-unscrollable;

    background-color: var(--secondary);
    color: #fff;

    padding: 0.8rem;

    font-size: 0.6rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-transform: uppercase;

    transition: all 0.3s ease-in-out;

    z-index: 1;

    &:hover {
      color: var(--secondary);
    }

    &::after {
      content: "";
      position: absolute;

      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      background-color: #fff;

      transform-origin: left;
      transform: scaleX(0);

      transition: transform 0.3s ease-in-out;

      z-index: -1;
    }

    &:hover::after {
      transform: scaleX(1);
    }
  }
`;
