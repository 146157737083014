import styled from 'styled-components';

export const SectionTemplate = styled.section`
  height: 100vh;
  min-height: 800px;
  max-height: 900px;
  background-color: var(--primary);
  padding: 1rem 0;

  @media screen and (max-width: 700px) {
    height: auto;
    max-height: unset;
    min-height: unset;
  }
`;

export const GridContainer = styled.div`
  max-width: 1050px;
  margin: 0 auto;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
  grid-gap: 0.4rem;
  /* border: solid green; */

  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.div`
  height: 336px;
  width: 334px;
  background-color: var(-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LogoArea = styled.div`
  margin-bottom: 0.3rem;
`;

export const TextArea = styled.div`
  text-transform: uppercase;
  p:first-child {
    color: #fff;
    font-size: 1.7rem;
    font-family: 'Gotham-Light';
    letter-spacing: 2px;
  }
  p:last-child {
    color: var(--secondary);
    font-size: 1.6rem;
    font-family: 'Gotham-Bold';
    letter-spacing: 2px;
  }
`;
