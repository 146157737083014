export const Hover = {
  leave: {
    height: '0%',
    opacity: 0,
    transition: {
      duration: 0.85,
      ease: 'easeOut',
    },
  },
  hover: {
    height: '43%',
    opacity: 1,
    transition: {
      duration: 0.55,
      ease: 'easeOut',
      type: 'tween',
    },
  },
};

export const LineScale = {
  leave: {
    scaleX: 0,
    transition: {
      duration: 0.75,
      ease: 'easeOut',
    },
  },
  hover: {
    scaleX: 1,
    transition: {
      duration: 0.75,
      ease: 'easeOut',
    },
  },
};
export const CardVisible = {
  hidden: {
    y: 500,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.75,
      ease: 'easeOut',
    },
  },
};

export const ImageScale = {
  leave: {
    scale: 1,
    transition: {
      duration: 0.75,
      ease: 'easeOut',
    },
  },
  hover: {
    scale: 1.025,
    transition: {
      duration: 0.75,
      ease: 'easeOut',
    },
  },
};
