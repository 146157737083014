import styled from 'styled-components';
import { motion } from 'framer-motion';

export const SectionTemplate = styled(motion.section)`
  background-color: var(--primary);
  padding: 1rem 0;
  overflow: hidden;

  @media screen and (max-width: 700px) {
    height: auto;
  }
`;

export const Container = styled(motion.div)`
  max-width: 1040px;
  height: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;

  @media screen and (max-width: 700px) {
    max-width: unset;
    justify-content: center;
    gap: 1rem;
  }
`;
