import styled from 'styled-components';
import { motion } from 'framer-motion';

export const SectionTemplate = styled(motion.section)`
  height: 100vh;
  position: relative;

  @media (max-width: 700px) {
    max-width: 100vw;
  }
`;

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  right: 50%;
  height: 300px;
  width: 400px;
  transform: translateX(50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5rem;
  padding-bottom: 2.2rem;

  @media (max-width: 700px) {
    width: auto;
    height: auto;
  }
`;

export const BannerContainer = styled.div`
  width: 980px;
  height: 380px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);

  @media (max-width: 700px) {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
`;

export const BannerLogoArea = styled.div`
  grid-row: span 2;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-right: 3rem;

  @media (max-width: 700px) {
    margin: 0;
  }
`;

export const BannerLogo = styled.img``;

export const BannerTitle = styled.div`
  grid-row: span 2;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;

  p {
    font-family: 'Gotham-Light', sans-serif;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 1.5rem;
  }

  p:last-child {
    font-family: 'Gotham-Bold', sans-serif;
    color: var(--secondary);
  }
`;

export const LogoBox = styled.div`
  width: 130px;
  height: 130px;
  position: absolute;
  top: 0;
  right: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 1.5rem; */
  transform: translateX(50%);
  z-index: 1;
  /* border: solid red; */
`;

export const Logo = styled.img``;

export const Circle = styled.div`
  width: 55px;
  height: 55px;
  border: 1.5px solid #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const InnerCircle = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Arrow = styled.img``;

export const Button = styled.button`
  padding: 16px 28px;
  border-radius: 1px;
  font-size: 0.6rem;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--secondary);
  letter-spacing: 1px;
  position: relative;
  z-index: 2;
  transition: all 0.4s ease-in-out;

  &:hover {
    color: var(--secondary);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    transform-origin: left;
    transform: scaleX(0);
    transition: all 0.4s ease-in-out;
    z-index: -1;
  }

  &:hover:before {
    transform: scale(1);
  }
`;
